<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Đối soát từ thiện</h6>
        </template>
        <div class="filter">
          <b-row>
            <b-col>
              <b-form-group label="Mã giao dịch">
                <b-form-input v-model="filter.payment_id"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Số điện thoại">
                <b-form-input v-model="filter.user_phone"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Chiến dịch">
                <b-form-select :options="products" v-model="filter.product_id"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Đơn vị tổ chức">
                <b-form-select :options="providers" v-model="filter.provider_id"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Trạng thái đơn hàng">
                <b-form-select :options="orderStatus" v-model="filter.status"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Trạng thái giao dịch">
                <b-form-select :options="transactionStatus" v-model="filter.trans_status"></b-form-select>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="Thời gian bắt đầu(*)">
                <b-form-input
                    :id="`type-date`"
                    :type="`date`"
                    v-model="filter.date_from"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Thời gian kết thúc(*)">
                <b-form-input
                    :id="`type-date`"
                    :type="`date`"
                    v-model="filter.date_to"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="text-right">
                <b-button-group>
                  <b-button type="button" variant="primary" @click="search()"><i class="fa fa-search"></i> Tìm kiếm</b-button>
                  <b-button type="button" variant="success" @click="showModal()"><i class="fa fa-download"></i> Xuất Excel</b-button>
                </b-button-group>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="content">
          <div class="mb-5" v-if="filter.product_id">
            <b-row>
              <b-col>
                <strong>Số lượt quyên góp:</strong> <strong class="text-success">{{ paginate.total }} lượt</strong>
              </b-col>
              <b-col>
                <strong>Số tiền/Số tiền mục tiêu:</strong> <strong class="text-success">{{ process > 0 ? numberFormat(process) : 0 }}đ/{{ target > 0 ? numberFormat(target) : 0 }}đ</strong>
              </b-col>
            </b-row>
          </div>

          <b-table :items="items" :fields = "fields" ref="table" striped hover responsive caption-top>
            <template #cell(index)="data">
              {{(currentPage - 1) * 25 + (data.index + 1)}}
            </template>

            <template #cell(product.name)="data">
              <a href="/#/charity/campaign-charities" target="_blank">{{ data.item.product.name }}</a>
            </template>

            <template #cell(user_id)="data">
              UUID: {{data.item.user_id}}
              <br>
              {{data.item.user ? data.item.user.phone : '--'}}
            </template>
            <template #cell(created_at)="data">
              {{ formatDate(data.item.created_at) }}
            </template>
            <template #cell(amount)="data">
              {{ numberFormat(data.item.amount) }}
            </template>
          </b-table>

          <div class="mt-3">
            <b-pagination-nav
                v-model="currentPage"
                :link-gen="createLinkPage"
                :number-of-pages="paginate.totalPage"
                use-router
                first-number
                last-number
                align="right"
            ></b-pagination-nav>
          </div>
        </div>
      </b-card>
    </b-card-group>

    <export-modal :modalId="exportId" @exportData="exportData"></export-modal>
    <alert-dialogue ref="confirmDialogue"></alert-dialogue>
  </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import ExportModal from "@/view/pages/components/ExportModal.vue";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";
import router from "@/router";

Vue.use(Notifications);

const ReconcileRepository = RepositoryFactory.get("reconcile");
export default {
  components: { AlertDialogue, ExportModal },
  mixins: [Common],
  data() {
    return {
      exportId: "export_charity_reconcile",
      items: null,
      products: [],
      providers: [],
      process: 0,
      target: 0,
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      filter: {
        date_from: this.getFirstDayOfMonth(),
        date_to: this.getCurrentDay(),
        status: null,
        trans_status: null,
        product_id: null,
        provider_id: null,
        payment_id: null,
        user_phone: null
      },
      orderStatus: [
        { value: "", text: "Tất cả" },
        { value: "CREATE", text: "CREATE" },
        { value: "CREATED", text: "CREATED" },
        { value: "SUCCESS", text: "SUCCESS" },
        { value: "FAIL", text: "FAIL" },
        { value: "PAYMENT_CANCEL", text: "PAYMENT_CANCEL" },
        { value: "PAYMENT_PENDING", text: "PAYMENT_PENDING" },
        { value: "PAYMENT_SUCCESS", text: "PAYMENT_SUCCESS" },
        { value: "EXPIRED_SESSION", text: "EXPIRED_SESSION" },
        { value: "PROCESSING", text: "PROCESSING" },
        { value: "WAIT_TO_REVIEW", text: "WAIT_TO_REVIEW" },
        { value: "REJECT", text: "REJECT" },
        { value: "CANCEL", text: "CANCEL" },
      ],
      transactionStatus: [
        { value: "", text: "Tất cả" },
        { value: "PENDING", text: "PENDING" },
        { value: "SUCCESS", text: "SUCCESS" },
        { value: "CREATE", text: "CREATE" },
        { value: "CREATE_ERROR", text: "CREATE_ERROR" },
        { value: "CREATE_REPLACE", text: "CREATE_REPLACE" },
        { value: "CREATE_SUCCESS", text: "CREATE_SUCCESS" },
        { value: "CREATED", text: "CREATED" },
        { value: "FAIL", text: "FAIL" },
        { value: "CANCEL", text: "CANCEL" },
        { value: "PROCESSING", text: "PROCESSING" },
        { value: "REFUND", text: "REFUND" },
        { value: "REVERSED", text: "REVERSED" },
      ],
      fields: [
        {
          key: "index",
          label: "STT",
        },
        {
          key: "user_id",
          label: "User",
        },
        {
          key: "payment_id",
          label: "Mã giao dịch"
        },
        {
          key: "amount",
          label: "Số tiền"
        },
        {
          key: "type_link",
          label: "PTTT"
        },
        {
          key: "description",
          label: "Nội dung"
        },
        {
          key: "product.name",
          label: "Tên chiến dịch"
        },
        {
          key: "product.provider.name",
          label: "Tổ chức từ thiện"
        },
        {
          key: "status",
          label: "Trạng thái"
        },
        {
          key: "trans_status",
          label: "Trạng thái giao dịch"
        },
        {
          key: "created_at",
          label: "Thời gian"
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Đối soát từ thiện", route: "charity" },
    ]);
  },
  methods: {
    search() {
      this.filter.page = 1;
      router.push({ path: "/reconcile/charity", query: this.filter }).catch(() => {});

      if (this.currentPage !== 1) {
        this.currentPage = 1;
        return;
      }

      this.getTransactions();
    },
    async getTransactions()
    {
      this.$bus.$emit("show-loading", true);
      this.items = null;
      let params = this.convert(this.filter);
      params.page = this.currentPage;
      let response = await ReconcileRepository.getListCharity(params);

      this.$bus.$emit("show-loading", false);
      let body = response.data;
      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      this.items = body.data.list.data;
      this.process = body.data.sum;
      this.target = body.data.target;
      this.paginate.totalPage = body.data.list.last_page;
      this.paginate.total = body.data.list.total;
      this.paginate.page = body.data.list.current_page;
      this.paginate.limit = body.data.list.per_page;
    },
    showModal() {
      this.$bvModal.show(this.exportId);
    },
    async exportData(description = null) {
      this.$bus.$emit("show-loading", true);
      let params = this.convert(this.filter);
      params.export_desc = description
      this.$bvModal.hide(this.exportId);

      let response = await ReconcileRepository.exportCharity(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;
      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      this.$bus.$emit("show-loading", false);
      let link = window.location.origin + "/#/reconcile/export-data";
      let cfm = await this.$refs.confirmDialogue.show({
        title: "Download File",
        message: "File đang được xử lý, bạn có thể tải xuống sau ít phút <a href='" + link + "' target='_blank'>TẠI ĐÂY</a>",
        okButton: "Ok",
      });

      if (!cfm) {
        self.$bus.$emit("show-loading", false);
        return (self.errorMessage = "Có lỗi khi lấy thông tin");
      }
    },
    convert(params) {
      Object.keys(params).forEach(key => {
        if (params[key] === null || params[key] == '') {
          delete params[key];
        }
      });
      return params;
    },
    async getProducts()
    {
      this.$bus.$emit("show-loading", true);
      this.products = [{ value: "", text: "Tất cả" }];
      let params = null;
      let response = await ReconcileRepository.getListProductsCharity(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;
      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      let products = body.data;

      let self = this;
      if (products) {
        products.forEach(function (item) {
          self.products.push({ value: item.id, text: item.name });
        });
      }
    },
    async getProviders()
    {
      this.$bus.$emit("show-loading", true);
      this.providers = [{ value: "", text: "Tất cả" }];
      let params = null;
      let response = await ReconcileRepository.getListProvidersCharity(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;
      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      let providers = body.data;
      let self = this;
      if (providers) {
        providers.forEach(function (item) {
          self.providers.push({ value: item.id, text: item.name });
        });
      }
    },
  },
  created() {
    this.getTransactions();
    this.getProducts();
    this.getProviders();
  },
  watch: {
    currentPage(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getTransactions();
      }
    },
  },
};
</script>
